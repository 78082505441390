@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

@import url('../font/stylesheet.css');
@import url('./style-filter.css');
@import url('./form.css');
html * {
  font-family: 'Roboto', sans-serif;
}

.nav-user-avatar img {
  width: 40px;
  height: 40px;
}

.ant-btn-background-ghost {
  color: black !important;
  border: 1px solid #d9d9d9 !important;
}

.radio-button-option input[type='radio'],
.radio-button-option input[type='checkbox'] {
  display: none;
}

.radio-button-option input[type='radio'] + label,
.radio-button-option input[type='checkbox'] + label {
  display: inline-block;
  margin: -2px;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  background-repeat: repeat-x;
  border: 1px solid #ccc;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.radio-button-option input[type='radio']:checked + label,
.radio-button-option input[type='checkbox']:checked + label {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #e0e0e0;
}

.sc-chat-window {
  height: calc(100% - 200px) !important;
}

.page-detail > .row {
  border: 1px solid #f2f2f2;
  padding: 10px 5px;
  font-weight: bold;
  margin: 5px 0px;
}

.page-detail > .row:nth-child(even) {
  background-color: #f4f4f4;
}

.collapse-detail {
  border: 1px solid #f2f2f2;
  background: #f9f9f9;
  /* margin: 2px 10px; */
  padding: 5px 10px;
}
.collapse-detail .row {
  padding: 10px 0px;
}

.container-loading {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 50%;
  top: 50%;
  z-index: 11111;
  background: red;
}

.section-loading {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(245, 245, 245, 0.5);
  top: 0;
  z-index: 1;
}

.nav-link .nav-icon {
  color: #37474f !important;
}

.nav-link.active .nav-icon {
  color: #fff !important;
}

.nav-tabs .nav-link.active {
  border-top: 2px solid #2cc185;
  border-bottom-color: #fff;
  color: #2cc185;
}

.nav-tabs .nav-link.active:hover {
  color: #2cc185;
}

.nav-tabs .nav-link {
  border: 1px solid rgba(40, 44, 42, 0.1);
  border-bottom: none;
  border-radius: 0px;
  padding: 0.5rem 2rem;
  background-color: rgba(40, 44, 42, 0.05);
  font-weight: bold;
}

.form-fieldset {
  border: rgba(0, 0, 0, 0.1) 1px solid;
  padding: 10px;
  border-radius: 5px;
}

.fieldset-legend {
  border: rgba(0, 0, 0, 0.1) 1px solid;
  width: auto;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.custom-badge {
  position: relative;
}

.custom-badge .badge {
  position: absolute;
  font-size: 8px;
  color: white;
  right: 0;
  transform: translateX(15px);
}

.badge-success.outline {
  background-color: white;
  border: #28a745 1px solid;
  color: #28a745;
}

.ant-message {
  z-index: 9999 !important;
}

.section-detail .row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.anticon {
  vertical-align: bottom;
}
@import url('./style-card.css');
@import url('./style-button.css');
@import url('./style-modal.css');
